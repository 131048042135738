import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { tahmee_james } from './tahmee/james'
import { Controller, useForm } from "react-hook-form";
import TextField from '@mui/material/TextField'
import { morphgnt_sbl_james as sbl } from './greek text/sblgnt-morphgnt/80-Jas-morphgnt'
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button'
import { saveAs } from 'file-saver';
import Dialog from '@mui/material/Dialog'
import { firebaseConfig } from '../../../firebase/setup'
// import { firebaseConfig } from '../../../../firebaseConfig'
import {TranslationServiceClient} from '@google-cloud/translate'
import { google_translator_one } from './google/google_translator_test_one'

type iSBLID = string
type iTranslationID = string

type iInterlinearGroup = {
    chapter?: number;
    verse?: number;
    paragraph?: boolean;
    sbl?: iSBLID[];
    grisson_phonetic?: iTranslationID[];
    grisson_literal?: iTranslationID[];
    google?: number;
}

type iInterlinearTranslation = {
    [key: iTranslationID]: {
        i: number,
        txt: string,
    };
}

export default function InterlinearC() {
    // const translationClient = new TranslationServiceClient()
    const { handleSubmit, reset, control, getValues } = useForm();
    const onSubmit = (data: any) => {

    };


    const [ edit, setEdit ] = useState(false)
    const [ editBlockIndex, setEditBlockIndex ] = useState<number | undefined>()
    const [ editTranslationID, setEditTranslationID ] = useState<string | undefined>()
    const [ editTranslationName, setEditTranslationName ] = useState<undefined | string>()
    const [ triggerRender, setTriggerRender ] = useState(0)

    const [ seeDetails, setSeeDetails ] = useState(false)
    const [ details, setDetails ] = useState({
        morphology: {
            part_of_speech: '',
            code: ''
        },
        lema: '',
    })

    const textRef = useRef<HTMLInputElement | null>(null);

    const props = {inputProps:{autoComplete: 'off'}}

    function translateTwo() {
        let fromLang = 'el';
        let toLang = 'es';
        let text = 'ὁλόκληρος ἐν';

        const API_KEY = firebaseConfig.apiKey;

        let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
        url += '&q=' + encodeURI(text);
        url += `&source=${fromLang}`;
        url += `&target=${toLang}`;

        fetch(url, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        }
        })
        .then(res => res.json())
        .then((response) => {
        console.log("response from google: ", response);
        })
        .catch(error => {
        console.log("There was an error with the translation request: ", error);
        });
    }


    const storeChanges = () => {
        if (!editBlockIndex || !editTranslationName) {
            console.log('Will return because undefined parameters')
            console.log({editBlockIndex}, {editTranslationName})
            return
        }
        console.log('Will store changes')
        // get data for literal translation
        const local_interlinear_translation = localStorage.getItem(`grisson_interlinear_${editTranslationName}`)
        const interlinear_translation: iInterlinearTranslation = local_interlinear_translation ? JSON.parse(local_interlinear_translation) : {}
        // get text box content
        const text = getValues('Texto')
        // update data for literal translation
        const generate_new_translation_id = () => {
            let max = 0
            Object.entries(interlinear_translation).forEach((item, index) => {
                const key = item[0]
                if (Number(key) > max) {
                    max = Number(key)
                }
            })
            return (max + 1).toString()
        }
        const generated_translation_id = generate_new_translation_id()
        const new_translation_id: string = editTranslationID === undefined ? generated_translation_id : editTranslationID
        const updated_translation: iInterlinearTranslation = {
            ...interlinear_translation,
            [new_translation_id]: {
                i: Number(new_translation_id), //todo: watch out for this.
                txt: text
            },
        }
        // save data for literal translation
        const stringified_translation = JSON.stringify(updated_translation)
        localStorage.setItem(`grisson_interlinear_${editTranslationName}`, stringified_translation)

        // interlinear
        // get original item
        const local_interlinear_data = localStorage.getItem('grisson_interlinear_data')
        if (!local_interlinear_data) return
        const interlinear_data = JSON.parse(local_interlinear_data)
        const original_item = interlinear_data[editBlockIndex]
        // create updated item
        const updated_item = {
            ...original_item,
            // literal_ids: generate_new_translation_id()
            [`${editTranslationName}_ids`]: [editTranslationID === undefined ? new_translation_id : editTranslationID],
        }
        // update array
        const new_array = interlinear_data
        new_array[editBlockIndex] = updated_item
        // save array
        const stringified_interlinear = JSON.stringify(new_array)
        localStorage.setItem('grisson_interlinear_data', stringified_interlinear)
        console.log({editBlockIndex})
        console.log({editTranslationID})
        console.log({new_array})

        // restablish state
        setEditBlockIndex(undefined)
        setEditTranslationID(undefined)
        reset()

        // close edit mode
        setEdit(false)
        setTriggerRender(p => p + 1)
    }

    useEffect(() => {
        console.log('ue triggered')
        const switchThemes = (ev: KeyboardEvent) => {
            console.log('triggered')
            if (ev.key === "Enter") {
                console.log('Will try to store changes')
                storeChanges()
            } else {
                return
            }
        };
        window.addEventListener("keyup", switchThemes, false);
        return () => {
            window.addEventListener("keyup", switchThemes, false);
        };
    }, []);

    function build_interlinear_blocks() {
        let prev_chapter = ''
        let prev_verse = ''
        const result: iInterlinearGroup[] = []
        sbl.forEach((v, i) => {
            if (!v) {
                console.log('Error: sbl is undefined')
                return
            }
            const reference = v.split('')
            const current_chapter = reference[2] + reference[3]
            const current_verse = reference[4] + reference[5]
            const is_new_chapter = () => {
                return !(prev_chapter === current_chapter)
            }
            const is_new_verse = () => {
                return !(prev_verse === current_verse)
            }
            if (is_new_chapter()) {
                result.push({chapter: Number(current_chapter)})
                // result.push({verse: Number(current_verse)})
            }
            if (is_new_verse()) {
                result.push({verse: Number(current_verse)})
            }
            prev_chapter = current_chapter
            prev_verse = current_verse
            const new_group = {
                sbl: [i.toString()]
            }
            result.push(new_group)
        })
        console.log(result)
        localStorage.setItem('grisson_interlinear_data', JSON.stringify(result))
    }


    const local_interlinear_data = localStorage.getItem('grisson_interlinear_data')
    const interlinear_data = local_interlinear_data ? JSON.parse(local_interlinear_data) : []

    // translations
    const local_interlinear_literal_translation = localStorage.getItem('grisson_interlinear_literal')
    const interlinear_literal_translation = local_interlinear_literal_translation ? JSON.parse(local_interlinear_literal_translation) : {}

    const local_interlinear_fonetic_translation = localStorage.getItem('grisson_interlinear_fonetic')
    const interlinear_fonetic_translation = local_interlinear_fonetic_translation ? JSON.parse(local_interlinear_fonetic_translation) : {}

    // function that reads the files saved by the save() function and loads them into the local storage
    function load() {
        const input = document.createElement('input')
        input.type = 'file'
        input.accept = '.txt'
        input.onchange = (e) => {
            // @ts-ignore
            const file = (e.target as HTMLInputElement).files[0]
            const reader = new FileReader()
            reader.onload = (e) => {
                const text = (e.target as FileReader).result
                if (!text) return
                // get file title
                const file_title = file.name.split('.')[0]

                // if the name includes local_interlinear_data save it to local storage with that name
                if (file_title.includes('local_interlinear_data')) {
                    // parse into an array
                    const data = JSON.parse(text.toString())
                    localStorage.setItem('grisson_interlinear_data', JSON.stringify(data))
                }
                // if the name includes local_literal save it to local storage with that name
                if (file_title.includes('local_literal')) {
                    // parse into an array
                    const data = JSON.parse(text.toString())
                    localStorage.setItem('grisson_interlinear_literal', JSON.stringify(data))
                }
                // if the name includes local_fonetic save it to local storage with that name
                if (file_title.includes('local_fonetic')) {
                    // parse into an array
                    const data = JSON.parse(text.toString())
                    localStorage.setItem('grisson_interlinear_fonetic', JSON.stringify(data))
                }
            }
            reader.readAsText(file)
        }
        input.click()
    }

    function save() {
        if (!local_interlinear_data || !local_interlinear_fonetic_translation || !local_interlinear_literal_translation) {
            console.error({local_interlinear_data, local_interlinear_fonetic_translation, local_interlinear_literal_translation})
            return
        }
        if (!local_interlinear_data) return
        const data_blob = new Blob([local_interlinear_data], {type: "text/plain;charset=utf-8"})
        saveAs(data_blob, "local_interlinear_data.txt")

        const literal_blob = new Blob([local_interlinear_literal_translation], {type: "text/plain;charset=utf-8"})
        saveAs(literal_blob, "local_literal.txt")

        const fonetic_blob = new Blob([local_interlinear_fonetic_translation], {type: "text/plain;charset=utf-8"})
        saveAs(fonetic_blob, "local_fonetic.txt");

    }

    function parsePartOfSpeech(string: string) {
        switch (string) {
            case 'A-':
                // return 'adjective';
                return 'adjetivo';
            case 'C-':
                // return 'conjunction';
                return 'conjunción';
            case 'D-':
                // return 'adverb';
                return 'adverbio';
            case 'I-':
                // return 'interjection';
                return 'interjección';
            case 'N-':
                // return 'noun';
                return 'sustantivo';
            case 'P-':
                return 'preposición';
            case 'RA':
                // return 'definite article';
                return 'artículo determinado';
            case 'RD':
                return 'pronombre demonstrativo';
            case 'RI':
                return 'pronombre interrogativo/indefinido';
            case 'RP':
                return 'pronombre personal';
            case 'RR':
                return 'pronombre relativo';
            case 'V-':
                return 'verbo';
            case 'X-':
                return 'partícula';
        }
    }

    function parseMorphCode(string: string) {
        const array = string.split('')
        const person = () => {
            switch (array[0]) {
                case '1':
                    return 'primera persona';
                case '2':
                    return 'segunda persona';
                case '3':
                    return 'tercera persona';
                default: return ''
            }
        }
        const tense = () => {
            switch (array[1]) {
                case 'P': return 'presente';
                case 'I': return 'imperfecto';
                case 'F': return 'futuro';
                case 'A': return 'aoristo';
                case 'X': return 'perfecto';
                case 'Y': return 'plusperfecto';
                default: return ''
            }
        }
        const voice = () => {
            switch (array[2]) {
                case 'A': return 'activo';
                case 'M': return 'medio';
                case 'P': return 'pasivo';
                default: return ''
            }
        }
        const mood = () => {
            switch (array[3]) {
                case 'I': return 'indicativo';
                case 'D': return 'imperativo';
                case 'S': return 'subjuntivo';
                case 'O': return 'optativo';
                case 'N': return 'infinitivo';
                case 'P': return 'participio';
                default: return ''
            }
        }
        const case_ = () => {
            switch (array[4]) {
                case 'N': return 'nominativo';
                case 'G': return 'genitivo';
                case 'D': return 'dativo';
                case 'A': return 'accusativo';
                default: return ''
            }
        }
        const number = () => {
            switch (array[5]) {
                case 'S': return 'singular';
                case 'P': return 'plural';
                default: return ''
            }
        }
        const gender = () => {
            switch (array[6]) {
                case 'M': return 'masculino';
                case 'F': return 'feminino';
                case 'N': return 'neutro';
                default: return ''
            }
        }
        const degree = () => {
            switch (array[7]) {
                case 'C': return 'comparativo';
                case 'S': return 'superlativo';
                default: return ''
            }
        }

        const result = `
            ${person() && person() + ', '}
            ${tense() && tense() + ', '}
            ${voice() && voice() + ', '}
            ${mood() && mood() + ', '}
            ${case_() && case_() + ', '}
            ${number() && number() + ', '}
            ${gender() && gender() + ', '}
            ${degree() && degree() + ', '}
        `
        const lastIndex = result.lastIndexOf(',');

        const replacement = '.';

        const replaced =
            result.substring(0, lastIndex) +
            replacement +
            result.substring(lastIndex + 1);
        return replaced
    }

    // function that loops through interlinear_data and creates a new array of interlinear blocks by:
    // getting the word on sbl
    // finding that word in the google data
    // creating a new object with the sbl word, the google phonetics, the google translation, the grisson literal translation, and the morphology
    // pushing that object to the new array
    function setUpGoogle() {
        const new_interlinear_blocks: any[] = [];
        for (let i = 0; i < interlinear_data.length; i++) {
            if (!('sbl_ids' in interlinear_data[i])) {
                new_interlinear_blocks.push(interlinear_data[i])
                console.log('no sbl', interlinear_data[i])
            } else {
                console.log(sbl[i], interlinear_data.length, i)
                const sbl_word = sbl[interlinear_data[i].sbl_ids].split(' ')[3];
                const google_item = google_translator_one.findIndex((item: any) => item.word === sbl_word);
                const new_item = {
                    sbl: interlinear_data[i].sbl_ids,
                    grisson_literal: interlinear_data[i].literal_ids,
                    grisson_phonetic: interlinear_data[i].fonetic_ids,
                    google: google_item,
                }
                new_interlinear_blocks.push(new_item)
            }
        }
        console.log(new_interlinear_blocks)
        localStorage.setItem('grisson_interlinear_data', JSON.stringify(new_interlinear_blocks))
    }

    const display = {
        sbl: true,
        google_phonetics: true,
        google_translation: true,
        grisson_literal: true,
        morphology: true,
    }

    return (
        <>
            <Container
                sx={{
                    '& .MuiTypography-root': {
                        // fontFamily: "'Source Sans 3', Inter",
                        fontSize: '2.1rem'
                    }
                }}
            >
                <Fab aria-label="save" onClick={() => save()}
                    sx={{
                        position: 'fixed',
                        right: '75px',
                        bottom: '10px',
                    }}
                >
                    SAVE
                </Fab>
                <Fab aria-label="save" onClick={() => build_interlinear_blocks()}
                    sx={{
                        position: 'fixed',
                        right: '10px',
                        bottom: '10px',
                    }}
                >
                    RUN
                </Fab>
                <Fab aria-label="save" onClick={() => load()}
                    sx={{
                        position: 'fixed',
                        right: '140px',
                        bottom: '10px',
                    }}
                >
                    READ
                </Fab>
                <Fab aria-label="save" onClick={() => translateTwo()}
                    sx={{
                        position: 'fixed',
                        right: '205px',
                        bottom: '10px',
                    }}
                >
                    TRANSLATE
                </Fab>
                <Fab aria-label="save" onClick={() => setUpGoogle()}
                    sx={{
                        position: 'fixed',
                        right: '270px',
                        bottom: '10px',
                    }}
                >
                    SETUP GOOGLE
                </Fab>
                <Dialog open={seeDetails} onBackdropClick={() => setSeeDetails(false)}
                    sx={{
                        '& .MuiPaper-root': {
                            p: 4
                        }
                    }}
                >
                    <Typography>
                        { parsePartOfSpeech(details.morphology.part_of_speech) }
                    </Typography>
                    <Typography>
                        { parseMorphCode(details.morphology.code).replaceAll(', -', '.') }
                    </Typography>
                    <Typography>
                        { details.lema }
                    </Typography>
                </Dialog>
                {
                    useMemo(() => interlinear_data.map((group: iInterlinearGroup, i: number) => {
                        const { chapter, verse } = group
                        const sbl_ids = group.sbl
                        const literal_ids = group.grisson_literal
                        const fonetic_ids = group.grisson_phonetic
                        const google_ids = group.google

                        if ((sbl_ids && sbl_ids?.length > 1) || (literal_ids && literal_ids.length > 1) || (fonetic_ids && fonetic_ids.length > 1)) {
                            console.error('Not ready for multiple ids')
                        }
                        if (chapter) return <Typography key={'chapter' + i} sx={{
                            opacity: .6,
                            px: 1,
                            pt: 4,
                            pb: 1,
                            mr: '4px',
                            // fontFamily: 'Satoshi-Variable !important',
                        }}>Capítulo {chapter}</Typography>
                        if (verse) return <Typography key={'chapter' + i}
                            sx={{
                                opacity: .6,
                                px: 1,
                                py: 1,
                                mr: '4px',
                            }} component='span'>{verse}</Typography>

                        const sbl_content = sbl[Number(sbl_ids)]
                        if (!sbl_content) return console.error('sbl_content is undefined')
                        const sbl_item = sbl_content.split(' ')[3]
                        const speech_item = sbl_content.split(' ')[1]
                        const morphology_item = sbl_content.split(' ')[2]

                        const get_literal_content = literal_ids ? interlinear_literal_translation[literal_ids[0].toString()]?.txt : ''
                        const get_fonetic_content = fonetic_ids ? interlinear_fonetic_translation[fonetic_ids[0].toString()]?.txt : '-'
                        // console.log(Number(google_ids), google_translator_one.length, google_translator_one[Number(google_ids)], google_translator_one)
                        const get_google_content = google_ids ? google_translator_one[google_ids] : { word: '-', translation: '-', phonetic: '-' }

                        const literal_content =  get_literal_content ? get_literal_content : '-'
                        return (
                            <Box
                                key={'group' + i}
                                sx={{
                                    display: 'inline-flex',
                                    flexDirection: 'column',
                                    px: 1,
                                    py: 1,
                                    mr: '4px',
                                    borderBottom: '1px solid lightgray',
                                }}
                                >
                                <Box
                                    onClick={() => {
                                        const sbl_item = sbl[Number(sbl_ids)].split(' ')
                                        setDetails({
                                            morphology: {
                                                part_of_speech: sbl_item[1],
                                                code: sbl_item[2],
                                            },
                                            lema: sbl_item[6]
                                        })
                                        setSeeDetails(true)
                                    }}
                                    onContextMenu={(e) => {
                                        e.preventDefault()
                                        navigator.clipboard.writeText(sbl_item)
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            textAlign: 'center',
                                            fontFamily: "'Source Sans 3' !important",
                                            fontWeight: '600',
                                            fontVariationSettings: '"wght" 650 !important',
                                            letterSpacing: '-0.6px',
                                            fontSize: '2.5rem !important'
                                        }}
                                    >
                                        {sbl_item}
                                    </Typography>
                                </Box>
                                {/* <Box
                                    onClick={
                                        () => {
                                            setEdit(true)
                                            setEditBlockIndex(i)
                                            setEditTranslationID((literal_ids && literal_ids[0]) ? literal_ids[0] : undefined)
                                            setEditTranslationName('fonetic')
                                        }
                                    }
                                >
                                    <Typography
                                        sx={{
                                            textAlign: 'center',
                                            fontSize: '1.6rem !important',
                                            fontFamily: 'Satoshi-Variable !important',
                                            opacity: .7,
                                        }}
                                    >
                                        { fonetic_content }
                                    </Typography>
                                </Box> */}
                                <Box>
                                    <Typography
                                        sx={{
                                            textAlign: 'center',
                                            fontSize: '1.4rem !important',
                                            fontFamily: 'Satoshi-Variable !important',
                                            opacity: .7,
                                        }}
                                    >
                                        { speech_item } { morphology_item }
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            textAlign: 'center',
                                            fontSize: '1.4rem !important',
                                            fontFamily: 'Satoshi-Variable !important',
                                            opacity: .7,
                                        }}
                                    >
                                        { get_google_content.translation }
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{
                                            textAlign: 'center',
                                            fontSize: '1.4rem !important',
                                            fontFamily: 'Satoshi-Variable !important',
                                            opacity: .7,
                                        }}
                                    >
                                        { get_google_content.phonetic }
                                    </Typography>
                                </Box>
                                <Box
                                    onClick={
                                        () => {
                                            setEdit(true)
                                            setEditBlockIndex(i)
                                            setEditTranslationID((literal_ids && literal_ids[0]) ? literal_ids[0] : undefined)
                                            setEditTranslationName('literal')
                                        }
                                    }
                                >
                                    <Typography
                                        sx={{
                                            textAlign: 'center',
                                            fontSize: '2.1rem !important',
                                            letterSpacing: '0.1px',
                                            fontFamily: 'Satoshi-Variable !important'
                                        }}
                                    >
                                        { literal_content }
                                    </Typography>
                                </Box>
                            </Box>
                        )
                    })
                    , [triggerRender])
                }
            </Container>
            <Box>
                {
                    !edit ? null :
                        <Box
                            sx={{
                                position: 'fixed',
                                bottom: '10px',
                                left: '50%',
                                backgroundColor: '#fff',
                                p: 2,
                                borderRadius: '11px',
                                transform: 'translateX(-50%)'
                            }}
                        >
                            <form onSubmit={onSubmit}>
                                <Controller
                                    name={"Texto"}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField onChange={onChange} value={value || ''} label={"Texto"} {...props} autoFocus />
                                    )}
                                />
                                <Button
                                    type="submit"
                                    onClick={() => storeChanges()}
                                >
                                    OK
                                </Button>
                            </form>
                        </Box>
                }
            </Box>
        </>
    )
}