import { BreakpointsOptions } from "@mui/material";
import { createTheme, Theme } from '@mui/material/styles'

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        us: true;
    }
}

export const breakpoints = {
    us: 1,
    xs: 350,
    sm: 600,
    md: 900,
    lg: 1200,// used also found in the wild, in media queries useMediaQuery('(min-width:
    xl: 1536, 
}

export const mui_breakpoints = createTheme({
    breakpoints: {
        values: breakpoints,
    }
})

export const fire_colors = {
    lightblue: '#01579b',
    lightblue_bg: '#e1f5fe',
    violet_bg: '#f3e8fd',
    violet: '#9334e6',
    red: '#d50000',
    red_bg: '#fce8e6',
    orange_bg: '#feefe3',
    orange: '#bf360c',
    blue: '#3f51b5',
    blue_bg: '#e8eaf6',
    gray: '#546e7a',
    gray_bg: '#eceff1',
    green_bg: '#e0f2f1',
    green: '#00796b',
}

export const git_light_colors = {
    lightblue: '#01579b',
    lightblue_bg: '#e1f5fe',
    blue: '#005cc5',
    blue_bg: '#f1f8ff',
    green: '#22863a',
    green_bg: '#f0fff4',
    orange: '#e36209',
    orange_bg: '#fff8f2',
    red: '#cb2431',
    red_bg: '#ffeef0',
    pink: '#d03592',
    pink_bg: '#ffeef8',
    violet: '#5a32a4',
    violet_bg: '#f4f0ff',
}

export const git_dark_colors = {
    lightblue: '',
    lightblue_bg: '',
    blue: '#85beff',
    blue_bg: '#082a52',
    green: '#95f0ab',
    green_bg: '#184d25',
    orange: '#ffae75',
    orange_bg: '#a84603',
    red: '#ff808d',
    red_bg: '#8f1d22',
    pink: '#fa9bd4',
    pink_bg: '#702653',
    violet: '#b899f0',
    violet_bg: '#2e1757',
}

export const chr_dev_colors = {
    blue_lightest: 'rgba(232, 240, 254, 0.4)',
    blue_lighter: 'rgba(232, 240, 254, 0.54)',
    blue_medium: '#1967d2',
    blue_darkest: '#174ea6',
    yellow_lightest: 'rgba(254, 247, 224, 0.4)',
    yellow_lighter: 'rgba(254, 247, 224, 0.5)',
    yellow_medium: '#f29900',
    yellow_darkest: '#b05a00',
    red_lightest: 'rgba(252, 232, 230, 0.4)',
    red_lighter: 'rgba(252, 232, 230, 0.5)',
    red_medium: '#c5221f',
    red_darkest: '#a50e0e',
    green_lightest: 'rgba(230, 244, 234, 0.4)',
    green_lighter: 'rgba(230, 244, 234, 0.5)',
    green_medium: '#188038',
    green_darkest: '#0d652d',
    purple_lightest: 'rgba(243, 232, 253, 0.4)',
    purple_lighter: 'rgba(243, 232, 253, 0.5)',
    purple_medium: '#8430ce',
    purple_darkest: '#681da8',
    pink_lightest: 'rgba(253, 231, 243, 0.4)',
    pink_lighter: 'rgba(253, 231, 243, 0.5)',
    pink_medium: '#d01884',
    pink_darkest: '#9c166b',
    cyan_lightest: 'rgba(228, 247, 251, 0.4)',
    cyan_lighter: 'rgba(228, 247, 251, 0.5)',
    cyan_medium: '#129eaf',
    cyan_darkest: '#007b83',
}