import Box from '@mui/material/Box'
import { PropsWithChildren } from 'react'

export default function Quote(props: PropsWithChildren<{}>) {
    const { children } = props
    return (
        <Box
            sx={{
                pl: '0.7em',
                ml: 'calc(-3px - 0.7em)',
                borderLeft: `5px solid #3acfd5`,
                // borderImage: 'linear-gradient(to bottom, #3acfd5 0%, #3a4ed5 100%) 1',
                // borderImage: 'linear-gradient(to bottom, #3acfd5 0%, #515ada 100%) 1',
                // borderImage: 'linear-gradient(to bottom, #3acfd57f 0%, #3EBBD67f 100%) 1',
                borderImage: 'linear-gradient(to bottom, var(--quote-top) 0%, var(--quote-bottom) 100%) 1',
                // my: '3px',
                fontStyle: 'italic',
                fontSize: '.9em',
                lineHeight: '1.4',
                // my: '0.6em',
                my: '0.3em',
                // my: '0px',
            }}
        >
            { children }
        </Box>
    )
}