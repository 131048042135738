import React, { useState, createContext, useContext, ReactNode, useEffect } from 'react';
// import { rtdb } from '../components/Firebase/Firebase';
// import { iPopupTypes } from '../interfaces/iPopup';
// import { useAuth } from './AuthContext';
// import { ref, serverTimestamp, increment, update, set, onDisconnect } from '@firebase/database'
import { Theme } from '@mui/material/styles'
// import { extended_horizon, mui_horizon } from '../themes/horizon'
// import { extended_white, mui_white } from '../themes/white'
import { extended_blue, mui_blue } from '../themes/blue';
import { supported_fonts } from '../harmony/data/supported_fonts';
import { extended_dark, mui_dark } from '../themes/dark';
// import { v4 as uuid } from 'uuid';
// import { DateTime } from "luxon";
// import { useIdleTimer } from 'react-idle-timer'

type Props = {
    children: ReactNode
}

type iThemesArray = Array<{ name: string, mui: Theme, extended: iExtendedTheme }>

type iGlobalContextData = {
    // popup: iPopupTypes,
    // setPopup: React.Dispatch<React.SetStateAction<iPopupTypes>>,
    // closePopup: () => void,
    themes: iThemesArray,
    theme: {
        name: string,
        index: number,
    },
    mui_theme: Theme,
    extended_theme: iExtendedTheme,
    handleThemeChange: (theme_name: string) => void,
    realtimeHistory: (text: string, gid: string) => void,
    uppercase: boolean,
    bigFont: boolean,
    // localTime: boolean,
    switchBig: () => void,
    justify: boolean,
    switchJustify: () => void,
    verseNumbers: boolean,
    fontSize: number,
    fontFamily: typeof supported_fonts[0] | undefined,
    setFontFamily: React.Dispatch<React.SetStateAction<typeof supported_fonts[0] | undefined>>,
    switchUppercase: () => void,
    switchDark: () => void,
    switchVerseNumbers: () => void,
    switchFontFamily: (settings: any) => void,
    changeFontSize: (change: number) => void,
    dark: boolean,
    // small: boolean,
    // switchSmall: () => void,
}

export interface iExtendedTheme {
    // logo_small: string,
    // logo_big: string,
    font_text: string,
    font_display: string,
    font_text_variable?: string,
    font_display_variable?: string,
    colors: {
        primary: string,
        secondary: string,
        lines: string,
        text_primary: string,
        text_secondary: string,
        background_paper: string,
        background_default: string,
        blue: string,
        blue_bg: string,
        lightblue: string,
        lightblue_bg: string,
        green: string,
        green_solid: string,
        green_bg: string,
        orange: string,
        orange_bg: string,
        red: string,
        red_bg: string,
        pink: string,
        pink_bg: string,
        violet: string,
        violet_bg: string,
        gray: string,
        gray_bg: string,
    },
    marker: {},
    toolbar_height: string,
    // tight_borders: string,
    // wide_borders: string,
}

// @ts-ignore
const GlobalContext = createContext<iGlobalContextData>();

export function useGlobal() { // this is a custom hook
    return useContext(GlobalContext)
}

type gid = string

export function GlobalProvider(props: Props) {

    const { children } = props

    // const { record, identity } = useAuth()

    const [ theme, setTheme ] = useState<{name: string, index: number}>({name: 'Blue', index: 0})

    const [ uppercase, setUppercase ] = useState(false)
    const [ bigFont, setBigFont ] = useState(true)
    const [ verseNumbers, setVerseNumbers ] = useState(true)
    const [ fontFamily, setFontFamily ] = useState<typeof supported_fonts[0] | undefined>()
    const [ fontSize, setFontSize ] = useState<number>(0)
    const [ justify, setJustify ] = useState(true)
    const [ dark, setDark ] = useState<boolean>(false)
    // const [ small, setSmall ] = useState<boolean>(false)

    const themes: iThemesArray = [
        { name: 'Blue', mui: mui_blue, extended: extended_blue },
        { name: 'Dark', mui: mui_dark, extended: extended_dark },
        // { name: 'Horizon', mui: mui_horizon, extended: extended_horizon },
        // { name: 'White', mui: mui_white, extended: extended_white }
    ]

    const [ mui_theme, setMui_theme ] = useState<Theme>(themes[theme.index].mui)
    const [ extended_theme, setExtended_theme ] = useState<iExtendedTheme>(themes[theme.index].extended)

    // useEffect(() => {
    //     console.log('ue triggered')
    //     const switchThemes = (ev: KeyboardEvent) => {
    //         console.log('triggered')
    //       if (ev.key === "<") {
    //         setTheme((p) => {
    //             console.log(`index before: ${p.index}. themes.length: ${themes.length}`)
    //             const new_index = p.index === (themes.length - 1) ? 0 : p.index + 1
    //             console.log(`new i: ${new_index}`)
    //             const new_theme = { name: themes[new_index].name, index: new_index }
    //             localStorage.setItem('theme_name', themes[new_index].name)
    //             localStorage.setItem('theme_index', new_index.toString())
    //             console.log(new_theme)
    //             return new_theme
    //         })
    //       } else {
    //         return
    //       }
    //     };
    //     window.addEventListener("keyup", switchThemes, false);
        // return () => {
        //   window.addEventListener("keyup", switchThemes, false);
        // };
    // }, []);

    // const presence_ref = ref(rtdb, `/online/${identity ? identity.uid : ''}`);

    // const onIdle = () => {
        // if (!identity || !identity.uid) return
        // console.log('IDLE')
        // set(presence_ref, 'idle')
    // }
    // const onActive = () => {
        // if (!identity || !identity.uid) return
        // console.log('ACTIVE')
        // set(presence_ref, true)
    // }

    // const onAction = () => {}

    // const idleTimer = useIdleTimer({ onIdle, onActive, timeout: 1000 * 60 * 10 })
    
    // useEffect(() => {
        // if (!identity) return
        // set(presence_ref, true)
        // onDisconnect(presence_ref).set(serverTimestamp())
    // }, [identity])

    useEffect(() => {
        console.log('commit theme change triggered')
        setMui_theme(themes[theme.index].mui)
        setExtended_theme(themes[theme.index].extended)
        if (theme.name === 'Dark' && !dark) {
            switchDark()
        }
    }, [theme])

    useEffect(() => {
        const local_index = localStorage.getItem('theme_index')
        const local_name = localStorage.getItem('theme_name')
        const theme_exists = () => {
            return themes.find((v) => {
                return v.name === local_name
            })
        }
        if (!theme_exists()) return
        if (local_index && local_name) {
            setTheme({
                name: local_name,
                index: Number(local_index)
            })
        }
        const local_uppercase = localStorage.getItem('uppercase')
        const local_big_font = localStorage.getItem('big_font')
        if (local_uppercase && local_uppercase === 'true') setUppercase(true)
        if (local_big_font && local_big_font === 'true') setBigFont(true)
    }, [])

    // function closePopup() {
        // setPopup('')
    // }

    function handleThemeChange(theme_name: string) {
        let i = 0
        for (const thm of themes) {
            if (thm.name === theme_name) {
                const new_theme = { name: thm.name, index: i }
                setTheme(new_theme)
                localStorage.setItem('theme_name', thm.name)
                localStorage.setItem('theme_index', i.toString())
            }
            i++
        }
    }

    function switchBig() {
        setBigFont((p) => {
            localStorage.setItem('big_font', p ? 'false' : 'true')
            return !p
        })
    }



    function switchLocalTime() {

    }

    const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

    async function realtimeHistory(text: string, gid: string) {
        // if (!record) return
        // const uid = record.uid

        // const dt = DateTime.fromObject({}, {zone: 'America/Guatemala'});
        // const year = dt.year
        // const month = dt.month
        // const day = dt.day
        // const week = dt.weekNumber

        // const random = uuid().slice(0, 4)

        // const newEntry = {
        //     t: serverTimestamp(),
        //     m: text,
        // }

        // if (!areStaff) {
        //     const logsRef = ref(rtdb, `groups/${gid}/logs`)
        //     const updates: {[key: string]: any} = {}
        //     updates[`/entries/${uid}/${random}`] = newEntry
        //     updates[`/aggregation/${uid}/total`] = increment(1)
        //     updates[`/aggregation/${uid}/${year}/${month}/${week}/${day}`] = increment(1)
    
        //     update(logsRef, updates)
        // } else {
        //     const logsRef = ref(rtdb, `staff/logs`)
        //     const updates: {[key: string]: any} = {}
        //     updates[`/entries/${uid}/${random}`] = newEntry
        //     updates[`/aggregation/${uid}/total`] = increment(1)
        //     updates[`/aggregation/${uid}/${year}/${month}/${week}/${day}`] = increment(1)
    
        //     update(logsRef, updates)
        // }
    }

    function changeFontSize(change: number) {
        setFontSize((p) => {
            localStorage.setItem('font_size', change.toString())
            return change
        })
    }

    function switchUppercase() {
        setUppercase((p) => {
            localStorage.setItem('uppercase', p ? 'false' : 'true')
            return !p
        })
    }

    function switchDark() {
        setDark((p) => {
            localStorage.setItem('dark', p ? 'false' : 'true')
            return !p
        })
    }

    function switchVerseNumbers() {
        setVerseNumbers((p) => {
            localStorage.setItem('verse_numbers', p ? 'false' : 'true')
            return !p
        })
    }

    function switchJustify() {
        setJustify((p) => {
            localStorage.setItem('harmony_justify', p ? 'false' : 'true')
            return !p
        })
    }

    function switchFontFamily(settings: any) {
        setFontFamily((p) => {
            if (!settings) {
                localStorage.setItem('font_family', '')
            } else {
                localStorage.setItem('font_family', JSON.stringify(settings))
            }
            
            return settings
        })
    }

    const data: iGlobalContextData = {
        // popup,
        // setPopup,
        // closePopup,
        themes,
        theme,
        mui_theme,
        extended_theme,
        handleThemeChange,
        realtimeHistory,
        uppercase,
        bigFont,
        // localTime,
        switchUppercase,
        switchBig,
        justify,
        switchJustify,
        verseNumbers,
        fontSize,
        fontFamily,
        setFontFamily,
        switchDark,
        switchVerseNumbers,
        switchFontFamily,
        changeFontSize,
        dark,
        // small,
    }

    return <GlobalContext.Provider value={data}>{ children }</GlobalContext.Provider>
}